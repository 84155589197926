import { Button, Icon, IconButton, Link, Typography } from '@components/ui';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { modalTitleIndent } from '@components/ui/Modal/styled';

const SIGN_BUTTON_BOTTOM = 150;
const SIGN_BUTTON_WIDTH = 140;
const SIGN_BUTTON_HEIGHT = 45;

export const Background = styled.picture(
    ({ theme, src }) => `
	max-height: 100%;
	min-height: calc(100vh - ${theme.page.header.height}px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - ${theme.page.header.height}px);
	width: 100%;
   	@media only screen and (min-aspect-ratio: 9/16) and (max-width: 767px) {
		height: 100%;
	}
	&::after {
		content: "";
		background: url(${src});
		background-repeat: no-repeat;
    	background-position: 50% 0;
    	background-size: cover;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
	}
`,
);

export const StyledCarousel = styled(Carousel)(
    ({ theme }) => `
    .carousel .slide {
        background: ${theme.color.cardBackground};
        height: 100%;
    }

`,
);

export const Dot = styled.button(
    ({ theme, $selected }) => `
    border: 1px solid ${theme.color.welcomeGalleryActiveBullet};
    display: block;
    padding: 6px;
    opacity: 0.7;
    box-shadow: none;
    background: ${theme.color.welcomeGalleryBullet};
    transition: opacity 0.25s ease-in;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    &:hover {
    	 background: ${theme.color.welcomeGalleryActiveBullet};
    }
    ${$selected &&
        `
    	 background: ${theme.color.welcomeGalleryActiveBullet};
    `}
`,
);

export const SignBtn = styled(Button)(
    ({ theme }) => `
    width: ${SIGN_BUTTON_WIDTH}px;
    height: ${SIGN_BUTTON_HEIGHT}px;
    align-self: center;
    margin-bottom: 50px;
    background: ${theme.color.signUpButtonBgColor};
    &:hover {
        background: ${theme.color.signUpButtonHoverBgColor};
   }
   	${theme.media.md`
		margin-bottom: 20px;
	`}
`,
);

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`;

export const Body = styled(Typography).attrs(() => ({ variant: 'subtitleWg' }))(
    ({ theme }) => `
    text-align: center;
	line-height: 32px;
	max-width: ${theme.sizes.xs}px;
	margin: 0 auto;
    color: ${theme.color.welcomeGalleryTextColor};
    @media only screen and (min-aspect-ratio: 9/17) and (max-width: 500px) {
    	font-size: 18px;
    	line-height: 24px;
   	}
`,
);

export const Title = styled(Typography).attrs(() => ({ variant: 'titleWg' }))(
    ({ theme }) => `
    text-align: center;
    margin-bottom: 20px;
    color: ${theme.color.welcomeGalleryTextColor};
`,
);

export const Area = styled.div(
    ({ theme }) => `
    width: 100%;
    height: calc(100vh - ${theme.page.header.height}px);
    text-align: center;
    padding: 65px 5px 0;
    position: relative;
    z-index: 1;
   	${theme.media.md`
		padding: 10px 5px 0;
	`}
`,
);

export const IllustrationBox = styled.div`
    display: inline-block;
    position: relative;
    margin-bottom: 54px;
`;

export const IllustrationCircles = styled(Icon).attrs(() => ({ id: 'il_welcome_bg_circles' }))(
    ({ theme }) => `
    position: absolute;
    left: 0;
    top: 0;
    path {
        fill: ${theme.color.welcomeCircles};
    }
`,
);

export const Illustration = styled(Icon)`
    position: relative;
    z-index: 1;
`;

export const MainIllustration = styled(Illustration)``;

export const DotsBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NavigationBox = styled.div(
    () => `
    z-index: 100;
	left: 0;
	right: 0;
	bottom: 75px;
	width: fit-content;
    margin: 0 auto;
    @media only screen and (max-aspect-ratio: 9/16) and (max-width: 500px) {
    	bottom: 20px;
   	}
`,
);

export const ArrowIcon = styled(Icon)(
    ({ theme }) => `
	path {
		fill: ${theme.color.welcomeGalleryArrows};
	}
`,
);

const ArrowButton = styled(IconButton)`
    width: auto;
    height: auto;
    background: none;
`;

export const PrevButton = styled(ArrowButton)`
    margin-right: 20px;
`;
export const NextButton = styled(ArrowButton)`
    margin-left: 20px;
`;

export const IntroVideo = styled.div`
    text-align: center;
    width: 100%;
    margin-top: 24px;
`;

export const VideoLink = styled.a(
    ({ theme }) => `
        color: ${theme.color.welcomeGalleryTextColor};
        display: inline-block;
        padding: 8px 10px;
        border-radius: 5px;
        margin: 0 10px 0 0;
        vertical-align: middle;
        font-size: 20px;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
`,
);

export const VideoIcon = styled.a`
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
`;

export const EmailButtonLink = styled(Link)(
    ({ theme }) => `
	a {
		font-family: ${theme.font.regular};
	}
`,
);

export const RectivationButton = styled.button(
    ({ theme }) => `
	color: ${theme.color.primary};
	width: 50%;
	display: flex;
	align-items: center;
	border: none;
	text-transform: uppercase;
	background-color: ${theme.color.textContrast};
	border-right: 1px solid ${theme.color.lightGrey};
	justify-content: center;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	padding: 0;
	height: 55px;
	cursor: pointer;
	text-decoration: underline;
`,
);

export const ButtonBox = styled.div(
    ({ theme }) => `
    display: flex;
    border-top: 1px solid ${theme.color.lightGrey};
    position: absolute;
    left: 0;
    right: 0;
    bottom: -35px;
    border-radius: 6px;
    background: ${theme.color.cardBackground};

`,
);

export const ReactivationTitle = styled(Typography).attrs(() => ({ variant: 'h4' }))(
    ({ theme }) => `
		${modalTitleIndent(theme)};
`,
);

export const TextModalBox = styled(Typography).attrs(() => ({ variant: 'body' }))`
    margin-bottom: 16px;
    text-align: center;
`;
