import { post, get } from 'src/common/api/request';
import routes from '@constants/apiRoutes';

export function login({ email, password }) {
    return post(`${routes.auth}/login`, { email, pass: password });
}

export function loginSSO() {
    return get(`${routes.auth}/login/sso`);
}

export function checkSSO(data) {
    return get(`${routes.auth}/callback${data}`, null, data);
}

export function logout() {
    return get(`${routes.auth}/logout`);
}

export function checkCode(code) {
    return post(`${routes.auth}/check-code`, { code });
}

export function getResetPassUrl(data) {
    return get(`${routes.auth}/password`, null, data);
}

export function updatePassword(data) {
    return post(`${routes.auth}/update-password`, data);
}
