import { createAction } from '@utils/redux';
import * as types from './actionTypes';

export const loginRequest = createAction(types.LOAD_LOGIN_REQUEST);
export const loginSuccess = createAction(types.LOAD_LOGIN_SUCCESS);
export const loginFailure = createAction(types.LOAD_LOGIN_FAILURE);

export const loginSSORequest = createAction(types.LOAD_LOGIN_SSO_REQUEST);
export const checkSSORequest = createAction(types.CHECK_LOGIN_SSO_REQUEST);

export const checkCodeRequest = createAction(types.LOAD_CODE_REQUEST);
export const checkCodeSuccess = createAction(types.LOAD_CODE_SUCCESS);
export const checkCodeFailure = createAction(types.LOAD_CODE_FAILURE);

export const checkCouponCodeRequest = createAction(types.LOAD_COUPON_CODE_REQUEST);
export const checkCouponCodeSuccess = createAction(types.LOAD_COUPON_CODE_SUCCESS);
export const checkCouponCodeFailure = createAction(types.LOAD_COUPON_CODE_FAILURE);

export const resetPasswordRequest = createAction(types.LOAD_RESET_PASSWORD_REQUEST);
export const resetPasswordSuccess = createAction(types.LOAD_RESET_PASSWORD_SUCCESS);
export const resetPasswordFailure = createAction(types.LOAD_RESET_PASSWORD_FAILURE);

export const clearAccessCode = createAction(types.CLEAR_ACCESS_CODE);
export const clearError = createAction(types.CLEAR_ERROR_FIELD);

export const logOut = createAction(types.LOGOUT);
export const logOutSuccess = createAction(types.LOGOUT_SUCCESS);

export const updatePasswordRequest = createAction(types.UPDATE_PASSWORD_REQUEST);
export const updatePasswordSuccess = createAction(types.UPDATE_PASSWORD_SUCCESS);
export const updatePasswordFailure = createAction(types.UPDATE_PASSWORD_FAILURE);
