import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

export const formatMessage = (id, defaultMessage) => {
    const intl = useIntl();
    return intl.formatMessage({ id, defaultMessage });
};

export const formatUnitValue = (value, unitId) => (
    <FormattedMessage id="UNIT_VALUE" values={{ value: value || '-', unit: <FormattedMessage id={unitId} /> }} />
);

formatMessage.propTypes = {
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
};
