import { REDIRECT_URL, BLOOD_VALUES } from '@constants';

const PROTECTED_ITEMS = [REDIRECT_URL, BLOOD_VALUES];

const getStorageItem = (key, storage) => {
    let value = storage.getItem(key);
    try {
        value = JSON.parse(value);
    } catch (e) {
        value = '';
    }

    return value;
};

export function setItem(keys = [], values = []) {
    return values.forEach((value, i) => localStorage.setItem(keys[i], JSON.stringify(value)));
}

export function getItem(key) {
    return getStorageItem(key, localStorage);
}

export function removeItem(key) {
    return localStorage.removeItem(key);
}

const getProtectedItems = () => {
    const items = {};
    PROTECTED_ITEMS.forEach(key => {
        items[key] = getItem(key);
    });
    return items;
};
const saveProtectedItems = items => PROTECTED_ITEMS.forEach(key => setItem([key], [items[key]]));

export function clearStorage() {
    const items = getProtectedItems();
    localStorage.clear();
    saveProtectedItems(items);
}
