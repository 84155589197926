import { clientRoutes } from '@constants';

export const consentsMap = {
    additionalTC: [
        {
            key: 'consentCollectPersonalInfo',
            required: true,
            label: 'I_CONSENT_TO',
            link: 'CONSENT_COLLECT_PERSONAL_INFO_LINK',
            linkLabel: 'CONSENT_COLLECT_PERSONAL_INFO_LABEL',
        },
        {
            key: 'consentProvidePersonalInfo',
            required: true,
            label: 'I_CONSENT_TO',
            link: 'CONSENT_PROVIDE_PERSONAL_INFO_LINK',
            linkLabel: 'CONSENT_PROVIDE_PERSONAL_INFO_LABEL',
        },
        {
            key: 'consentCollectSensitiveInfo',
            required: true,
            label: 'I_CONSENT_TO',
            link: 'CONSENT_COLLECT_SENSITIVE_INFO_LINK',
            linkLabel: 'CONSENT_COLLECT_SENSITIVE_INFO_LABEL',
        },
        {
            key: 'consentCdmMarketing',
            required: false,
            label: 'I_CONSENT_TO',
            link: 'CONSENT_CDM_MARKETING_LINK',
            linkLabel: 'CONSENT_CDM_MARKETING_LABEL',
        },
        {
            key: 'consentThirdPartyMarketing',
            required: false,
            label: 'I_CONSENT_TO',
            link: 'CONSENT_THIRD_PARTY_MARKETING_LINK',
            linkLabel: 'CONSENT_THIRD_PARTY_MARKETING_LABEL',
        },
    ],
    pdpaConsent: [
        {
            key: 'consentCollectSensitiveInfo',
            required: true,
            label: 'SENSITIVE_CONSENT_LABEL',
            link: 'SENSITIVE_CONSENT_LINK',
            linkLabel: 'SENSITIVE_CONSENT_LINK_LABEL',
        },
        {
            key: 'consentCdmMarketing',
            required: true,
            label: 'MARKETING_CONSENT_LABEL',
        },
    ],
    dataConsent: [
        {
            key: 'dataconsent',
            required: true,
            label: 'DATA_CONSENT_LABEL',
        },
    ],
};

export const consentParams = {
    dataConsent: {
        hideRequiredLabel: true,
        additionalText: 'DATA_CONSENT_FOOTER',
        nextButtonLabel: 'CONTINUE',
    },
};

export const showConditions = {
    pdpaConsent: (config, { prevLocation }) => {
        const needsPhoneConfirmation = config?.features?.phone?.needsConfirmation;
        return needsPhoneConfirmation ? prevLocation === clientRoutes.phone() : prevLocation === clientRoutes.signup();
    },
};
