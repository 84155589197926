export const clientRoutes = {
    activities: () => '/activities',
    activityPage: (id = ':id') => `/activities/${id}`,
    additionalConsent: () => '/additionalConsent',
    articlePage: (id = ':id') => `/discover/${id}`,
    biometric: () => '/biometric',
    callback: () => '/callback',
    coach: () => `/coach`,
    confirmEmail: () => '/confirmEmail',
    confirmPrivacy: () => '/confirmPrivacy',
    discover: () => `/discover`,
    login: () => '/login',
    me: () => '/me',
    moves: () => '/social',
    onboarding: () => '/onboarding',
    partnerProgram: (id = ':id') => `/partnerProgram/${id}`,
    phone: () => '/phone',
    profile: tab => `/profile${tab ? `?tab=${tab}` : ''}`,
    program: (id = ':id') => `/program/${id}`,
    programsession: (id = ':id') => `/programsession/${id}`,
    root: () => '/',
    settings: () => '/settings',
    signup: () => '/signup',
    social: tab => `/social${tab ? `?tab=${tab}` : ''}`,
    subscription: () => '/subscription',
    terms: (id = ':id') => `/terms/${id}`,
    challenge: (id = ':id') => `/social/challenge/${id}`,
};

export const unAuthRoutes = [clientRoutes.callback(), clientRoutes.login(), clientRoutes.root(), clientRoutes.signup()];

export const publicRoutes = [clientRoutes.activityPage(), clientRoutes.articlePage(), clientRoutes.terms()];
