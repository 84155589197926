import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const dash = keyframes`
	0% {
		stroke-dasharray: 2, 155;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 122, 20;
		stroke-dashoffset: -20;
	}
	100% {
		stroke-dasharray: 2, 155;
		stroke-dashoffset: -155;
	}
`;

const rotate = keyframes`
	100% {
		transform: rotate(360deg);
	}
`;

const defaultSize = 40;
const getStrokeWidth = size => (size > defaultSize ? size / 10 - 1 : size / 10);

const Spinner = styled.svg.attrs(({ size = defaultSize, thickness = getStrokeWidth(size) }) => ({
    viewBox: `${size / 2} ${size / 2} ${size} ${size}`,
    width: `${size}px`,
    height: `${size}px`,
    strokeWidth: `${thickness}px`,
}))`
    display: flex;
    align-content: center;
    align-self: center;
    justify-content: center;
    animation: ${rotate} 2s linear infinite;
`;

const Circle = ({ size = defaultSize }) => (
    <Spinner size={size}>
        <circle cx={size} cy={size} r={size / 2 - size / 10} />
    </Spinner>
);

const StyledLoader = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    align-self: center;
    justify-content: center;
    circle {
        fill: none;
        stroke: ${props => (props.inverted ? props.theme.color.cardBackground : props.theme.color.primary)};
        stroke-dasharray: 2, 155;
        stroke-dashoffset: 0;
        animation: ${dash} 1.7s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
`;

const Loader = props => (
    <StyledLoader {...props}>
        <Circle {...props} />
    </StyledLoader>
);

export const CenteredLoader = styled(Loader)`
    position: absolute;
`;

export const Loadable = ({ loading, children = <></>, size, className }) =>
    loading ? <Loader size={size} className={className} /> : children;

export const BlockLoader = ({ loading, children = <></>, size, className }) =>
    loading ? <CenteredLoader size={size} className={className} /> : children;

Loadable.propTypes = {
    loading: PropTypes.bool,
};

export default Loader;
