import { filter } from 'ramda';
import { getDuration, getDurationAndUnit } from '@utils/date';
import { formatUnitValue } from '@utils/intl';
import { getIdsMap } from '@utils';
import { ADD_VALUE } from '@components/Cards/Dashboard/utils';
import { unitsOptions } from '@constants/units';
import { METRIC } from '@constants';

const ids = [
    'SLEEP',
    'STEPS',
    'TOTAL_BURN',
    'WEIGHT',
    'ASCENT',
    'BMI',
    'WORKOUTS',
    'WORKOUT_DURATION',
    'WORKOUT_DISTANCE',
];

export const MAX_CELLS = 6;
export const DEFAULT_CELLS = 4;
export const CELL_IDS = getIdsMap(ids);

export const INITIAL_CELLS = [CELL_IDS.SLEEP, CELL_IDS.STEPS, CELL_IDS.TOTAL_BURN, CELL_IDS.WEIGHT];

export const generateCells = (summary = {}, user = {}) => {
    const system = user.unitSystem || METRIC;
    const userWeight = user.weight?.mass;
    const weight = (userWeight && unitsOptions.weight.value(userWeight, 'weight', system).toFixed(2)) || 0;
    const ascent = (summary.ascent && unitsOptions.ascent.value(summary.ascent, 'ascent', system)) || 0;
    const userDistance = summary.distance ? parseFloat(summary.distance.replace(/,/g, '')) : 0;
    const showMeters = userDistance < 1000;
    const convertedDistance = showMeters ? userDistance : userDistance / 1000;
    const distanceUnits = showMeters ? 'workoutDistance_meter' : 'workoutDistance';
    const distance =
        (convertedDistance && unitsOptions[distanceUnits].value(convertedDistance, 'distance', system).toFixed(2)) || 0;
    const sleepSettings = getDurationAndUnit(summary.sleep);
    const sleepValue = formatUnitValue(sleepSettings.duration, sleepSettings.unitId);
    const normEnergyValue = formatUnitValue(summary.energy, 'energy_value_kcal_name');
    const weightValue = formatUnitValue(weight, unitsOptions.weight[system]);
    const ascentValue = formatUnitValue(ascent, unitsOptions.ascent[system]);
    const distanceValue = formatUnitValue(distance, unitsOptions[distanceUnits][system]);
    const workoutsDurationValue = summary.duration && getDuration(summary.duration);
    const bmiValue = user?.bmis?.value?.toFixed(1);

    return [
        { id: CELL_IDS.SLEEP, icon: 'ic_sleep_l', value: sleepValue, label: 'wheel_sleep_title' },
        { id: CELL_IDS.STEPS, icon: 'ic_steps_l', value: summary.steps, label: 'dashboard_steps_title' },
        {
            id: CELL_IDS.TOTAL_BURN,
            icon: 'ic_workouts_totalburn_l',
            value: normEnergyValue,
            label: 'dashboard_total_burn_title',
        },
        { id: CELL_IDS.WEIGHT, icon: 'ic_weight_l', value: weightValue, label: 'body_value_weight_name' },
        { id: CELL_IDS.ASCENT, icon: 'ic_workout_ascent_l', value: ascentValue, label: 'dashboard_ascent_title' },
        { id: CELL_IDS.BMI, icon: 'ic_bmi_l', value: bmiValue, label: 'body_value_bmi_name' },
        {
            id: CELL_IDS.WORKOUTS,
            icon: 'ic_workout_l',
            value: summary.workouts,
            label: 'dashboard_workouts_count_title',
        },
        {
            id: CELL_IDS.WORKOUT_DURATION,
            icon: 'ic_workout_duration_l',
            value: workoutsDurationValue,
            label: 'dashboard_workout_duration_title',
        },
        {
            id: CELL_IDS.WORKOUT_DISTANCE,
            icon: 'ic_workout_distance_l',
            value: distanceValue,
            label: 'dashboard_distance_title',
        },
    ];
};

export const filterCells = (availableCells, cellsToShow) => {
    const availableCellsMap = availableCells.reduce((acc, cell) => ({ ...acc, [cell.id]: cell }), {});

    const toShow = cellsToShow.map(cellId => availableCellsMap[cellId]);
    const available = availableCells.filter(({ id }) => !cellsToShow.includes(id));

    return { toShow, available };
};

export const excludeAddCells = filter(cell => cell.id !== ADD_VALUE);
export const showAddCell = cells => cells.length % 2;
