import { push } from 'connected-react-router';
import { call, put, takeLatest, actionChannel, take, select } from 'redux-saga/effects';
import * as actions from '@services/auth/actions';
import { setItem, clearStorage, getItem } from '@utils/storageUtils';
import { closeModalRequest, openModalRequest } from '@services/modal/actions';
import {
    BLOOD_VALUES,
    MGDL,
    REACTIVATION_MODAL,
    SHOW_SPLITS,
    UPDATE_PASSWORD_MODAL,
    USER_ID,
    USER_NAME,
} from '@constants';
import { loadBrandingFailure, loadBrandingRequest, loadBrandingSuccess } from '@services/branding/actions';
import { addSuccessNotification, addErrorNotification, clearNotifications } from '@services/notifications/actions';
import { login, loginSSO, logout, checkCode, getResetPassUrl, updatePassword, checkSSO } from './api';

const HOME_PATH = '/';

export function* loginSaga(action) {
    try {
        const { data } = yield call(login, action.payload);
        yield put(loadBrandingRequest({ first: true }));
        yield put(clearNotifications());
        const channel = yield actionChannel([loadBrandingSuccess, loadBrandingFailure]);
        yield take(channel);
        const bloodValues = getItem(BLOOD_VALUES) || MGDL;
        yield setItem([USER_ID, USER_NAME, BLOOD_VALUES, SHOW_SPLITS], [data.id, data.name, bloodValues, false]);
        yield put(actions.loginSuccess(data));
    } catch (e) {
        yield put(actions.loginFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* loginSSOSaga() {
    try {
        const { data } = yield call(loginSSO);
        window.location.href = data.url;
    } catch (e) {
        yield put(addErrorNotification(e));
    }
}

export function* checkSSOSaga(action) {
    try {
        const { data } = yield call(checkSSO, action.payload);
        yield put(loadBrandingRequest({ first: true }));
        yield put(clearNotifications());
        const channel = yield actionChannel([loadBrandingSuccess, loadBrandingFailure]);
        yield take(channel);
        yield setItem([USER_ID, USER_NAME, BLOOD_VALUES, SHOW_SPLITS], [data.id, data.name, MGDL, false]);
        yield put(actions.loginSuccess(data));
    } catch (e) {
        yield put(addErrorNotification(e));
        yield put(push(HOME_PATH));
    }
}

export function* logoutSaga({ payload, meta }) {
    try {
        yield clearStorage();
        yield call(logout);
        yield put(actions.logOutSuccess());
        yield put(push(HOME_PATH));
        if (payload.showReactivationModal) yield put(openModalRequest({ name: REACTIVATION_MODAL, meta }));
    } catch (e) {
        yield put(actions.logOutSuccess());
        yield put(push(HOME_PATH));
    }
}

export function* accessCodeSaga(action) {
    try {
        const { data } = yield call(checkCode, action.payload?.accessCode);
        yield put(actions.checkCodeSuccess(data));
    } catch (e) {
        yield put(actions.checkCodeFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* resetPasswordSaga(action) {
    try {
        const { data } = yield call(getResetPassUrl, action.payload);
        yield put(actions.resetPasswordSuccess(data));
    } catch (e) {
        yield put(actions.resetPasswordFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* updatePasswordSaga(action) {
    try {
        const { data } = yield call(updatePassword, action.payload);
        yield put(actions.updatePasswordSuccess(data));
        yield put(closeModalRequest({ name: UPDATE_PASSWORD_MODAL }));
        yield put(addSuccessNotification());
    } catch (e) {
        yield put(actions.updatePasswordFailure(e));
        yield put(addErrorNotification(e));
    }
}

export function* couponCodeSaga(action) {
    try {
        const { data } = yield call(checkCode, action.payload.couponCode);
        yield put(actions.checkCouponCodeSuccess(data));
    } catch (e) {
        yield put(actions.checkCouponCodeFailure(e));
        yield put(addErrorNotification(e));
    }
}

export default function* Saga() {
    yield takeLatest(actions.loginRequest, loginSaga);
    yield takeLatest(actions.loginSSORequest, loginSSOSaga);
    yield takeLatest(actions.checkSSORequest, checkSSOSaga);
    yield takeLatest(actions.logOut, logoutSaga);
    yield takeLatest(actions.checkCodeRequest, accessCodeSaga);
    yield takeLatest(actions.resetPasswordRequest, resetPasswordSaga);
    yield takeLatest(actions.updatePasswordRequest, updatePasswordSaga);
    yield takeLatest(actions.checkCouponCodeRequest, couponCodeSaga);
}
