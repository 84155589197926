export const LOAD_PROGRAMS_REQUEST = 'LOAD_PROGRAMS_REQUEST';
export const LOAD_PROGRAMS_SUCCESS = 'LOAD_PROGRAMS_SUCCESS';
export const LOAD_PROGRAMS_FAILURE = 'LOAD_PROGRAMS_FAILURE';

export const LOAD_PAST_PROGRAMS_REQUEST = 'LOAD_PAST_PROGRAMS_REQUEST';
export const LOAD_PAST_PROGRAMS_SUCCESS = 'LOAD_PAST_PROGRAMS_SUCCESS';
export const LOAD_PAST_PROGRAMS_FAILURE = 'LOAD_PAST_PROGRAMS_FAILURE';

export const LOAD_PARTNER_CONTENT_REQUEST = 'LOAD_PARTNER_CONTENT_REQUEST';
export const LOAD_PARTNER_CONTENT_SUCCESS = 'LOAD_PARTNER_CONTENT_SUCCESS';
export const LOAD_PARTNER_CONTENT_FAILURE = 'LOAD_PARTNER_CONTENT_FAILURE';

export const LOAD_PROGRAM_REQUEST = 'LOAD_PROGRAM_REQUEST';
export const LOAD_PROGRAM_SUCCESS = 'LOAD_PROGRAM_SUCCESS';
export const LOAD_PROGRAM_FAILURE = 'LOAD_PROGRAM_FAILURE';

export const LOAD_PROGRAM_SESSIONS_REQUEST = 'LOAD_PROGRAM_SESSIONS_REQUEST';
export const LOAD_PROGRAM_SESSIONS_SUCCESS = 'LOAD_PROGRAM_SESSIONS_SUCCESS';
export const LOAD_PROGRAM_SESSIONS_FAILURE = 'LOAD_PROGRAM_SESSIONS_FAILURE';

export const LOAD_PROGRAM_SESSION_REQUEST = 'LOAD_PROGRAM_SESSION_REQUEST';
export const LOAD_PROGRAM_SESSION_SUCCESS = 'LOAD_PROGRAM_SESSION_SUCCESS';
export const LOAD_PROGRAM_SESSION_FAILURE = 'LOAD_PROGRAM_SESSION_FAILURE';

export const CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE';

export const DELETE_SESSION_REQUEST = 'DELETE_SESSION_REQUEST';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE';

export const SET_OPEN_EVENT = 'SET_OPEN_EVENT';
export const SET_OPEN_SESSION = 'SET_OPEN_SESSION';
export const SET_OPEN_PARTNER_PROGRAM = 'SET_OPEN_PARTNER_PROGRAM';
export const CLEAR_SESSIONS_REQUEST = 'CLEAR_SESSIONS_REQUEST';
export const CLEAR_EVENT_REQUEST = 'CLEAR_EVENT_REQUEST';

export const LOAD_USER_SESSIONS_REQUEST = 'LOAD_USER_SESSIONS_REQUEST';
export const LOAD_USER_SESSIONS_SUCCESS = 'LOAD_USER_SESSIONS_SUCCESS';
export const LOAD_USER_SESSIONS_FAILURE = 'LOAD_USER_SESSIONS_FAILURE';

export const LOAD_PARTNER_PROGRAM_REQUEST = 'LOAD_PARTNER_PROGRAM_REQUEST';
export const LOAD_PARTNER_PROGRAM_SUCCESS = 'LOAD_PARTNER_PROGRAM_SUCCESS';
export const LOAD_PARTNER_PROGRAM_FAILURE = 'LOAD_PARTNER_PROGRAM_FAILURE';
