import { FormattedMessage } from 'react-intl';
import React from 'react';

export default {
    firstName: {
        required: <FormattedMessage id="FIRST_NAME_REQUIRED" />,
        validation: <FormattedMessage id="FIRST_NAME_VALIDATION" />,
    },
    lastName: {
        required: <FormattedMessage id="LAST_NAME_REQUIRED" />,
        validation: <FormattedMessage id="LAST_NAME_VALIDATION" />,
    },
    dateOfBirth: {
        required: <FormattedMessage id="DATE_OF_BIRTH_REQUIRED" />,
    },
    gender: {
        required: <FormattedMessage id="GENDER_REQUIRED" />,
    },
    height: {
        required: <FormattedMessage id="HEIGHT_REQUIRED" />,
        positiveValidation: <FormattedMessage id="HEIGHT_POSITIVE_REQUIRED" />,
    },
    weight: {
        required: <FormattedMessage id="WEIGHT_REQUIRED" />,
        positiveValidation: <FormattedMessage id="WEIGHT_POSITIVE_REQUIRED" />,
    },
    email: {
        required: <FormattedMessage id="EMAIL_REQUIRED" />,
        validation: <FormattedMessage id="EMAIL_VALIDATION" />,
        match: <FormattedMessage id="EMAIL_MATCH_REQUIRED" />,
    },
    password: {
        currentRequired: <FormattedMessage id="CURRENT_PASSWORD_REQUIRED" />,
        newRequired: <FormattedMessage id="NEW_PASSWORD_REQUIRED" />,
        required: <FormattedMessage id="PASSWORD_REQUIRED" />,
        match: <FormattedMessage id="PASSWORDS_MATCH_REQUIRED" />,
        currentMatch: <FormattedMessage id="CURRENT_PASSWORD_MATCH_REQUIRED" />,
        validation: <FormattedMessage id="PASSWORD_VALIDATION" />,
        validationChar: <FormattedMessage id="PASSWORD_VALIDATION_CHAR" />,
    },
    country: {
        required: <FormattedMessage id="COUNTRY_REQUIRED" />,
    },
    county: {
        required: <FormattedMessage id="COUNTY_REQUIRED" />,
    },
    city: {
        required: <FormattedMessage id="CITY_REQUIRED" />,
    },
    confirmPrivacy: {
        required: <FormattedMessage id="PRIVACY_POLICY_REQUIRED" />,
    },
    myProfileDateOfBirth: {
        required: <FormattedMessage id="MY_PROFILE_DATE_OF_BIRTH_REQUIRED" />,
    },
};
