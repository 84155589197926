import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isAuthSelector } from '@services/auth/selectors';
import { setItem } from '@utils/storageUtils';
import { unAuthRoutes, REDIRECT_URL, publicRoutes } from '@constants';
import { __, includes } from 'ramda';

const Main = styled.main(
    ({ $centered }) => `
    width: 100%;
	${$centered && `margin: auto;`}
`,
);

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuth = useSelector(isAuthSelector);
    const isUnAuthRoute = includes(__, unAuthRoutes);
    const isPublicRoute = includes(__, publicRoutes);
    const authRequestsAuth = (isAuth, pathname) => isAuth && !isUnAuthRoute(pathname);
    const unauthRequestsUnauth = (isAuth, pathname) => !isAuth && isUnAuthRoute(pathname);
    const unauthRequestsAuth = (isAuth, pathname) => !isAuth && !isUnAuthRoute(pathname);

    const renderComponent = props => {
        const {
            location: { pathname },
            match: { path },
        } = props;

        if (unauthRequestsAuth(isAuth, path)) {
            setItem([REDIRECT_URL], [pathname]);
        }
        const showComponent =
            authRequestsAuth(isAuth, path) || unauthRequestsUnauth(isAuth, path) || isPublicRoute(path);
        return showComponent ? (
            <Main id="main" $centered={isUnAuthRoute(path)}>
                <Component {...props} />
            </Main>
        ) : (
            <Redirect to="/" />
        );
    };

    return <Route {...rest} render={renderComponent} />;
};
export default PrivateRoute;
