import { handleActions } from 'redux-actions';
import * as types from '@services/programs/actionTypes';
import { deduplicateArray } from '@utils/redux';
import { toApiFormat } from '@utils/date';

export const initialState = {
    details: {},
    events: [],
    pastEvents: [],
    partnerContent: {
        items: [],
        nextLink: null,
        details: {},
    },
    sessions: {
        items: [],
        userSessions: [],
        nextLink: null,
    },
};

const addParticipantId = (sessions, payload) =>
    sessions.items.map(session =>
        session.id === payload.programSession.id
            ? {
                  ...session,
                  participantId: payload.id,
              }
            : session,
    );

const addUserSessionToCollection = (sessions, userSessions, payload) => {
    const participatingSession = sessions.find(session => session.id === payload.programSession.id);
    return [...userSessions, { ...participatingSession, participantId: payload.id }];
};

export default handleActions(
    {
        [types.LOAD_PROGRAMS_SUCCESS]: (state, action) => ({
            ...state,
            events: action.payload.items,
        }),
        [types.LOAD_PAST_PROGRAMS_SUCCESS]: (state, action) => ({
            ...state,
            pastEvents: action.payload.items,
        }),
        [types.LOAD_PROGRAM_SUCCESS]: (state, action) => ({
            ...state,
            details: action.payload.data,
        }),
        [types.LOAD_PROGRAM_SESSION_SUCCESS]: (state, action) => ({
            ...state,
            sessions: {
                ...state.sessions,
                details: action.payload.data,
            },
        }),
        [types.DELETE_SESSION_SUCCESS]: (state, action) => ({
            ...state,
            sessions: {
                ...state.sessions,
                details: {
                    ...state.sessions.details,
                    participantId: null,
                },
                items: state.sessions.items.map(session =>
                    session.id === action.meta.sessionId
                        ? {
                              ...session,
                              participantId: null,
                          }
                        : session,
                ),
                userSessions: state.sessions.userSessions.filter(session => session.id !== action.meta.sessionId),
            },
            pastEvents: state.pastEvents.map(event =>
                event.id === action.meta.objectId
                    ? {
                          ...event,
                          isParticipating: false,
                      }
                    : event,
            ),
        }),
        [types.CREATE_SESSION_SUCCESS]: (state, action) => ({
            ...state,
            sessions: {
                ...state.sessions,
                details: {
                    ...state.sessions.details,
                    participantId: action.payload.id,
                },
                items: addParticipantId(state.sessions, action.payload),
                userSessions: addUserSessionToCollection(
                    state.sessions.items,
                    state.sessions.userSessions,
                    action.payload,
                ),
            },
            pastEvents: state.pastEvents.map(event =>
                event.id === action.payload.object.id
                    ? {
                          ...event,
                          isParticipating: true,
                      }
                    : event,
            ),
        }),
        [types.LOAD_PROGRAM_SESSIONS_SUCCESS]: (state, action) => {
            const items = action.payload.items.map(item => {
                const currentDate = toApiFormat(new Date());
                const type = item.endTime < currentDate ? 'past' : 'upcoming';
                return { ...item, type };
            });
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    items: deduplicateArray([...state.sessions.items, ...items]),
                    nextLink: action.payload.nextLink,
                },
            };
        },
        [types.SET_OPEN_EVENT]: (state, action) => ({
            ...state,
            details: action.meta.program,
        }),
        [types.SET_OPEN_PARTNER_PROGRAM]: (state, action) => ({
            ...state,
            partnerContent: {
                ...state.partnerContent,
                details: action.meta.program,
            },
        }),
        [types.SET_OPEN_SESSION]: (state, action) => ({
            ...state,
            sessions: {
                ...state.sessions,
                details: action.meta.session,
            },
        }),
        [types.CLEAR_SESSIONS_REQUEST]: state => ({
            ...state,
            sessions: {
                ...state.sessions,
                items: [],
                nextLink: null,
            },
        }),
        [types.CLEAR_EVENT_REQUEST]: state => ({
            ...state,
            details: {},
        }),
        [types.LOAD_PARTNER_CONTENT_SUCCESS]: (state, action) => ({
            ...state,
            partnerContent: {
                items: [...state.partnerContent.items, ...action.payload.items],
                nextLink: action.payload.nextLink,
            },
        }),
        [types.LOAD_USER_SESSIONS_SUCCESS]: (state, action) => ({
            ...state,
            sessions: {
                ...state.sessions,
                userSessions: action.payload.data,
            },
        }),
        [types.LOAD_PARTNER_PROGRAM_SUCCESS]: (state, action) => ({
            ...state,
            partnerContent: {
                ...state.partnerContent,
                details: action.payload.data,
            },
        }),
    },
    initialState,
);
