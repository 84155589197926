import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from '@components/ui';

const EXTERNAL_LINK_PATTERN = new RegExp('^(((https|http)://)|mailto:)(.*)');

const LinkBox = styled.span(
    ({ theme, underlined }) => `
    display: inline-flex;
    align-items: center;
    a {
    	display: flex;
        font-family: ${theme.font.bold};
        color: ${theme.color.primary};
        text-decoration: ${underlined ? 'underline' : 'none'};
        font-size: 16px;
        :hover {
            text-decoration: 'none';
        }
    }
`,
);

const InternalLink = styled(Link)`
    align-items: center;
`;
const ExternalLink = styled.a`
    align-items: center;
`;

const StyledLink = ({ icon: Icon, external, underlined, ...props }) => {
    const externalLink = props.to && EXTERNAL_LINK_PATTERN.test(props.to) ? props.to : `http://${props.to}`;

    return (
        <LinkBox underlined className={props.className}>
            {external ? (
                <ExternalLink href={externalLink} {...props} rel="noopener">
                    {props.children}
                    {Icon && <Icon />}
                </ExternalLink>
            ) : (
                <InternalLink {...props}>
                    {props.children}
                    {Icon && <Icon />}
                </InternalLink>
            )}
        </LinkBox>
    );
};

Link.propTypes = {
    underlined: PropTypes.bool,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.object,
};

export default StyledLink;
