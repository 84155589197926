import * as Yup from 'yup';
import messages from './messages';

const NAME_PATTERN = /^[\p{L}\p{N}\p{M}]+([ \-']?[\p{L}\p{N}\p{M}]+)*[.]?$/gu;

export const fieldValidation = {
    firstName: Yup.string()
        .required(messages.firstName.required)
        .matches(NAME_PATTERN, () => messages.firstName.validation)
        .test('firstName', messages.firstName.required, value => value?.trim()),
    lastName: Yup.string()
        .required(messages.lastName.required)
        .matches(NAME_PATTERN, () => messages.lastName.validation)
        .test('lastName', messages.lastName.required, value => value?.trim()),
    dateOfBirth: Yup.string().required(messages.dateOfBirth.required),
    gender: Yup.string().required(messages.gender.required),
    height: Yup.number()
        .required(messages.height.required)
        .positive(messages.height.positiveValidation),
    mass: Yup.number()
        .required(messages.weight.required)
        .positive(messages.weight.positiveValidation),
    country: Yup.string().required(messages.country.required),
    county: Yup.string().required(messages.county.required),
    city: Yup.string().required(messages.city.required),
};

export const getMyProfileValidation = (countryRequired, countyRequired) =>
    Yup.object().shape({
        name: Yup.object().shape({
            firstName: fieldValidation.firstName,
            lastName: fieldValidation.lastName,
        }),
        body: Yup.object().shape({
            dateOfBirth: Yup.string().required(messages.myProfileDateOfBirth.required),
            sex: fieldValidation.gender,
        }),
        custom: Yup.object().shape({
            country: countryRequired && fieldValidation.country,
            county: countyRequired && fieldValidation.county,
            city: fieldValidation.city,
        }),
    });

export default Yup.object().shape({
    name: Yup.object().shape({
        firstName: fieldValidation.firstName,
        lastName: fieldValidation.lastName,
    }),
    body: Yup.object().shape({
        dateOfBirth: fieldValidation.dateOfBirth,
        sex: fieldValidation.gender,
        height: fieldValidation.height,
    }),
    weight: Yup.object().shape({
        mass: fieldValidation.mass,
    }),
});
