import { reduce, identity, groupBy, prop } from 'ramda';
import { useIntl } from 'react-intl';

const KEY_LENGTH = 6;
export const ENTER_KEY_CODE = 13;

export const generateRandomString = length =>
    Math.random()
        .toString(36)
        .substr(2, length);

export const groupByKind = groupBy(prop('kind'));

export const getIdsMap = reduce((acc, id) => ({ ...acc, [id]: id }), {});

export const generateLoadObjects = length =>
    Array(length)
        .fill(identity)
        .map(() => ({ id: generateRandomString(KEY_LENGTH), empty: true }));

export const swap = (array, firstIndex, secondIndex) =>
    array.map((item, index) => {
        if (index === firstIndex) return array[secondIndex];
        if (index === secondIndex) return array[firstIndex];
        return item;
    });

export const move = (array, firstIndex, secondIndex) => {
    const options = array.slice();
    const option = array[firstIndex];
    options.splice(firstIndex, 1);
    options.splice(secondIndex, 0, option);
    return options;
};

export const handleKeyPress = (e, handleClick, value = null) => e.charCode === ENTER_KEY_CODE && handleClick(value);

export const generatePageTitle = title => {
    const intl = useIntl();
    document.title = intl.formatMessage({ id: 'WEB_PAGE_TITLE' }, { title });
    return document.title;
};

export const getLogoId = separateLogo => (separateLogo ? 'ic_logo_footer' : 'ic_logo_dacadoo');
