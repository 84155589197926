import { consentsMap, consentParams, showConditions } from '@constants/consent';

export const getInitialValues = consents =>
    consents.reduce((acc, consent) => {
        acc[consent.key] = false;
        return acc;
    }, {});

export const getConsentValues = (consents, values) =>
    consents.reduce((acc, consent) => {
        acc[consent.key] = values[consent.key];
        return acc;
    }, {});

const getConsentProp = (config, prop) => config?.features?.additionalConsent?.[prop];
const getCountries = config => getConsentProp(config, 'enabledForCountries') || {};
const getEnabledForAll = config => getConsentProp(config, 'enabledForAll');
const getConsentKey = (config, country) => getEnabledForAll(config) || getCountries(config)[country];

export const isConsentSupported = config => getEnabledForAll(config) || !!Object.keys(getCountries(config)).length;
export const isConsentEnabled = (country, config, params) => {
    if (getEnabledForAll(config)) {
        return true;
    }

    const countries = getCountries(config);
    if (!Object.keys(countries).includes(country)) {
        return false;
    }

    const consentKey = countries[country];
    const showCondition = showConditions[consentKey];
    return showCondition ? showCondition(config, params) : true;
};

export const isConsentAccepted = (custom = {}, country, config) => {
    const consentKey = getConsentKey(config, country);
    if (!consentKey) {
        return true;
    }

    const consents = consentsMap[consentKey] || [];
    return consents.reduce((acc, consent) => (consent.required ? acc && custom[consent.key] : acc), true);
};

export const getUserConsents = (user, config) => {
    const { country } = user?.custom || {};
    const consentKey = getConsentKey(config, country);
    return consentsMap[consentKey] || [];
};

export const getConsentParams = (user, config) => {
    const { country } = user?.custom || {};
    const consentKey = getConsentKey(config, country);
    return consentParams[consentKey] || {};
};
