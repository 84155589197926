export default {
    achievements: '/achievements',
    agreements: '/agreements',
    activities: '/activities',
    articles: '/blogs',
    auth: '/auth',
    avatar: id => `/users/action/import?id=${id}`,
    baseURL: '/api/1/',
    branding: '/brandings',
    cards: '/cards',
    challengeParticipants: '/challengeParticipants',
    getUserCP: '/challengeParticipants/action/getCurrentUserParticipant',
    challenges: '/challenges',
    checkTermsOfUse: '/stipulations/action/checkTermsOfUse',
    config: '/config',
    connections: '/connections',
    entities: '/entities',
    entitiesAction: action => `/entities/action/${action}`,
    financialscores: '/financialscores',
    getSupportUri: '/uris/action/getSupportUri',
    getUserSessions: '/programSessions/action/getUserSessions',
    goals: '/goals',
    goalsMessages: '/messages',
    healthscores: '/healthscores',
    messages: '/userMessages',
    messagesAction: action => `/userMessages/action/${action}`,
    moves: '/moves',
    pages: '/pages',
    posts: '/posts',
    programParticipants: '/programParticipants',
    programSessions: '/programSessions',
    programs: '/programs',
    relations: '/relations',
    sections: '/sections',
    services: '/services',
    stagings: '/stagings',
    stipulations: '/stipulations',
    strings: '/strings',
    summaries: '/summaries',
    tags: '/tags',
    updatePrivacy: '/users/action/updatePrivacy',
    updateSubscription: '/users/action/updateSubscription',
    uris: '/uris',
    users: '/users',
};
